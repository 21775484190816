{
  "en": {
    "account": {
      "info": {
        "cta": "Update",
        "error_email": "Email has already been registered",
        "input_one": "Name",
        "input_two": "Email",
        "title": "Update your basic info"
      },
      "password": {
        "cta": "Update password",
        "input_one": "Current password",
        "input_two": "New password",
        "title": "Update your password"
      },
      "title": {
        "main": "Account Settings",
        "subtitle": "Manage your data associated with your account"
      }
    },
    "activerecord": {
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        },
        "models": {
          "event": {
            "attributes": {
              "photo": {
                "not_image": "must be a common image format (.jpg, .png, .webp)",
                "too_large": "must not be larger than 5 megabytes"
              }
            }
          },
          "user": {
            "attributes": {
              "password_confirmation": {
                "confirmation": "Oops, the passwords doesn't match"
              },
              "reset_password_token": {
                "invalid": "Reset password token is invalid or has expired"
              }
            }
          }
        }
      }
    },
    "api_guard": {
      "access_token": {
        "expired": "Access token expired",
        "invalid": "Invalid access token",
        "missing": "Access token is missing in the request",
        "refreshed": "Token refreshed successfully"
      },
      "authentication": {
        "invalid_login_credentials": "Invalid login credentials",
        "signed_in": "Signed in successfully",
        "signed_out": "Signed out successfully"
      },
      "password": {
        "changed": "Password changed successfully"
      },
      "refresh_token": {
        "invalid": "Invalid refresh token",
        "missing": "Refresh token is missing in the request"
      },
      "registration": {
        "account_deleted": "Account deleted successfully",
        "signed_up": "Signed up successfully"
      },
      "response": {
        "error": "error",
        "success": "success"
      }
    },
    "authentication": {
      "forgot_password": {
        "cta": "Send password reset instructions",
        "error": "The entered email was not found in our records.",
        "textbox": "Enter the Email address asociated with your account.",
        "title": "Forgot your password?"
      },
      "login": {
        "cta": {
          "forgot_password": "Forgot Password?",
          "login": "Login",
          "sign_up": "Don't have an account?",
          "sign_up_link": "Sign up"
        }
      },
      "signup": {
        "cta": {
          "create": "Create my account",
          "login": "Already have an account?",
          "login_link": "Login."
        },
        "labels": {
          "confirm_password": "Confirm Password",
          "email": "Email",
          "email_validation": "Hmm... that doesn't look like an email address (error)",
          "name": "Name",
          "password": "Password",
          "password_validation": "Oops, the passwords don't match (error)"
        },
        "title": "Create your Account"
      }
    },
    "birthday_person": {
      "cta": {
        "modal": "Back to gifts",
        "title": "Now it's your turn to create your own Birthday Hello Event!"
      },
      "textbox": "Now it's the time to celebrate and start opening your gifts from all your loved ones. Simply click each box to uncover what's behind!",
      "title": {
        "principal": "WELCOME TO your {age} Birthday 🎁🎉",
        "welcome": "hi ✋ {birthday_person}"
      }
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "subject": "Email Changed"
        },
        "password_change": {
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "unlocks": {
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "already_confirmed": "was already confirmed, please try signing in",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "event_card": {
      "awaiting": "Awaiting",
      "gift": "gift",
      "gifts": "gifts",
      "open": "Open",
      "received": "received",
      "sample": {
        "create": "create event",
        "tag": "Sample"
      },
      "view": "View"
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      }
    },
    "guest": {
      "confirmation": {
        "cta": {
          "button": "Create your own",
          "textbox": "In just a few minutes, you can create an unforgettable event just like this one to celebrate that special someone.",
          "title": "Now it's your turn to create your own Birthday Hello Event!"
        },
        "textbox": "We successfully received all your gifts! Contact the host if you need to make any changes or for additional questions.",
        "title": "Gifts Sent!"
      },
      "wizard": {
        "confirmation_step": {
          "cta": {
            "confirm": "Confirm and Send",
            "top": "Top"
          },
          "disclaimer": {
            "and": "and",
            "info": "We will never sell your information to any third party.",
            "intro": "By submitting this form you agree to Birthday Hello's",
            "privacy_policy": "Privacy Policy",
            "terms_of_service": "Terms of Service"
          },
          "message_function": {
            "add": "Add Message",
            "message": "Your message:"
          },
          "summary_function": {
            "change": "Change",
            "gifts": "Your gifts"
          },
          "textbox": "Double-check before sending. Once sent, the host will receive a notification with your submission!",
          "title": "Confirm all your gifts 🎁🎁🎁️"
        },
        "email_step": {
          "cta": "Continue",
          "label": "Enter your email address",
          "textbox": "Don't worry! We will never sell your information.",
          "title": "WHAT’S YOUR EMAIL? 📫📫"
        },
        "message_step": {
          "cta": {
            "continue": "Continue",
            "skip": "Or skip this step",
            "upload": "Add your message"
          },
          "placeholder": {
            "left": "{charactersLeft} characters left",
            "message": "Type your message here..."
          },
          "textbox": "Write some words that you'd like to share with {birthday_person} on their birthday.",
          "title": "Wanna share a message? 💬"
        },
        "name_step": {
          "cta": "Continue",
          "placeholder": "Enter your name or group's name",
          "textbox": "If you're submitting as a group, feel free to type in the group's name such as The O'Connor Family.",
          "title": "Hi! 🖐️ What's your name?"
        },
        "photo_step": {
          "cta": {
            "continue": "Continue",
            "skip": "Or skip this step",
            "upload": "Upload Photos",
            "uploading": "Uploading..."
          },
          "textbox": "If you have pictures with the birthday person, this is the place to upload them! Upload up to 5 photos.",
          "title": "Wanna share some photos? 🖼 📷",
          "upload_function": {
            "info": "Upload up to 5 photos.",
            "pick": "Pick your favourite memories."
          }
        },
        "video_step": {
          "cta": {
            "continue": "Continue",
            "skip": "Or skip this step",
            "upload": "Upload Video",
            "uploading": "Uploading..."
          },
          "textbox": "Please upload your birthday greeting",
          "title": "Wanna share a video? 📽",
          "upload_function": {
            "error": "Error to upload video. Please try adding that file again...",
            "info": "Supported videos are .mov, .mp4, .mpeg, and others. They must be less than 300mb in size.",
            "loader": "Just a few seconds..."
          }
        },
        "welcome_step": {
          "cta": {
            "create_your_own": "Create your own",
            "start": "Start"
          },
          "send_by": "Send by",
          "textbox": "Hi, I'm making a Birthday Hello for {birthday_person} Birthday and everyone's help. Please send your own video birthday wishes and photos (it's simple). You'll be part of a remarkable surprise. Remember, she doesn't know. Thank you!",
          "title": "Hey! 🖐 Welcome to {birthday_person} {age} Birthday 🎁🎉"
        }
      }
    },
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "host": {
      "empty_space": {
        "textbox": "It is time to invite others to celebrate {birthday_person} Birthday. Simply share the invite link with guests.",
        "title": "No one is here yet!"
      },
      "event_dashboard": {
        "dashboard": {
          "cta": "Create Event",
          "textbox": "Create, see, and manage your events.",
          "title": "Hello party animal!"
        },
        "disclaimer": {
          "end": " automatically 7 days after event date.",
          "link": "hidden",
          "start": "Events are "
        },
        "history": {
          "hide": "Hide",
          "show": "Show",
          "text": " my past events"
        },
        "tiles": {
          "countdown": "{deadline} days ago",
          "cta": "view",
          "states": {
            "draft": "Draft",
            "published": "Published",
            "sample": "Sample",
            "shared": "Shared"
          }
        }
      },
      "event_manager": {
        "date_picker": {
          "limit": "Submissions allowed until 11:59 pm",
          "when": "Reopen until when?"
        },
        "deadline": {
          "after": "ago",
          "before": "to go",
          "day": "day",
          "days": "days",
          "same": "Today",
          "tomorrow": "1 day"
        },
        "event_notifications": {
          "deleted": "Event Deleted",
          "open": "Event is open for invites",
          "published": "Event Published"
        },
        "event_options": {
          "are_you_sure": "Are you sure?",
          "delete": "Delete",
          "edit": "Edit",
          "pay": "Pay",
          "publish": "Publish",
          "reopen": "Reopen"
        },
        "gift_modal": {
          "close": "Close",
          "confirmation": "Are you sure?",
          "remove": "Remove",
          "text": "You have the ability to remove media you don’t want published as part of this gift."
        },
        "invite_actions": {
          "copied": "Copied!",
          "copy": "Copy link",
          "hide": "Hide"
        },
        "labels": {
          "cta": {
            "copied": "Copied",
            "copy_link": "Copy link",
            "publish_event": "Publish Event"
          },
          "deadline": "Deadline",
          "guests": "Guests",
          "invite": "Invite",
          "none_yet": "None yet"
        },
        "lets_party": {
          "hide": "Hide",
          "note": "Note: Anyone that has this link will be able to see all shared gifts.",
          "textbox": "The event is now live! Use the following public link to share with {birthday_person} and others.",
          "title": "Let's Party!"
        },
        "options_menu": {
          "are_you_sure": "Are you sure?",
          "delete": "Delete",
          "edit": "Edit",
          "publish": "Publish",
          "reopen": "Reopen",
          "sr": "Open options menu"
        },
        "payment_reminder_banner": {
          "content_bold": "complete your payment",
          "content_normal": "You're almost there! Don't forget to",
          "prefix": "Psst!",
          "suffix": "to publish and share your event."
        },
        "publish_modal": {
          "content": "To unlock the magic link and share your event with {birthday_person}, please complete your payment of just $15.",
          "cta": "Yes, Let's Do It!",
          "title": "Almost ready to publish your event"
        },
        "texbox": "Hi, I'm making a Birthday Hello for {birthday_person} {age} Birthday and everyone's help. Please send your own video birthday wishes and photos (it's simple). You'll be part of a remarkable surprise. Remember, she doesn't know. Thank you!",
        "tiles": {
          "from": "From",
          "open": "Open"
        },
        "title": "{birthday_person} {age} birthday"
      },
      "inactive_event": {
        "textbox": "Sorry… don't want to be a party pooper but this event is no longer active. Please contact the host.",
        "title": "Opsss"
      },
      "new_event": {
        "age_selector": {
          "dont_know": "I don't know",
          "year": "year old",
          "years": "years old"
        },
        "characters_left": "{charactersLeft} characters left",
        "cta": {
          "cancel": "Cancel",
          "clear": "Clear",
          "create": "Create Event",
          "picture": "Recipient's picture",
          "save": "Save",
          "upload_photo": "Upload Photo"
        },
        "disclaimer": "Submissions allowed until midnight EST.",
        "file_size_error": "Photo size is too big (Max {file_size})",
        "labels": {
          "age": "How young? (Optional)",
          "birthday_person": "Recipient's Name",
          "deadline": "Deadline",
          "message": "Invitation Message",
          "name": "Events Name",
          "sample_texts": "Or pick from these sample texts",
          "theme": "Theme"
        },
        "message_placeholder": "Type your message here...",
        "sample_texts": {
          "formal": "Formal",
          "fun": "Fun",
          "loving": "Loving"
        },
        "textbox": "Give us some basic details",
        "title": {
          "default": "Let's create a new event! 🎉🎉🎉",
          "edition": "Let's edit your event! 🎉🎉🎉"
        }
      },
      "passed_event": {
        "textbox": "Sorry… don't want to be a party pooper but the deadline for this event has passed. Please contact the host for more info.",
        "title": "Opsss"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M"
      },
      "pm": "pm"
    }
  }
}